import React from 'react'

import Carousel from 'components/Carousel'
import Container from 'components/Container'
import Image from 'components/Image'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import testimonials from 'constants/testimonials'
import urls from 'constants/urls'

import styles from './Home.module.scss'
import photoImage from './images/photo.jpg'

export default function Home({ data }) {
  const { allFile } = data

  return (
    <Layout className="Home">
      {/*<Row>*/}
        <Carousel autoplaySpeed={6000} centerMode variableWidth className={styles['photos-carousel']}>
          {allFile.edges.map((item) => (
            <Image
              //src={item.node}
              src={item.node.childImageSharp.fluid.src}
              key={item.node.id}
            />
          ))}
        </Carousel>
      {/*</Row>*/}
      <Carousel autoplaySpeed={12000} className={styles['testimonials-carousel']}>
        {testimonials.map((item) => {
          return (
            <div key={item.author} style={{ width: '100%' }}>
              <Container size="small">
                <Text align="center" color="grey" copy element="blockquote">
                  <Text element="p" size="large" textStyle="italic" className={styles['testimonial--copy']}>“{item.copy}”</Text>
                  <Text block casing="upper" element="cite">
                    — {item.author}
                  </Text>
                </Text>
              </Container>
            </div>
          )
        })}
      </Carousel>
      <Container>
        <div style={{ position: 'relative' }}>
          <Image src={photoImage} />
          <Link to={urls.INSTAGRAM} style={{ display: 'block', textAlign: 'center', position: 'absolute', left: 0, right: 0, bottom: 32 }}>
            More photos of work available on <u>Instagram</u>
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "carousel" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
            #fluid(maxHeight: 500, quality: 75) {
            #  src
            #}
          }
        }
      }
    }
  }
`