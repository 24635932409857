import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
//import Flickity from 'react-flickity-component'
import Slick from "react-slick";

import '../../../node_modules/slick-carousel/slick/slick.css';
import './Carousel.scss'

export default function Carousel({ className, ...props}) {
  return (
    <Slick
      {...props}
      arrows={false}
      autoplay
      infinite
      slidesToShow={1}
      slidesToScroll={1}
      touchThreshold={10}
      className={classNames('Carousel', 'slider', 'variable-width', className)}
    />
  )
}

Carousel.propTypes = {
  centerMode: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variableWidth: PropTypes.bool,
}