import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import 'typeface-space-mono'

import Head from 'components/Head'
import Image from 'components/Image'
import Link from 'components/Link'
import Text from 'components/Text'
import meta from 'constants/meta'
import paths from 'constants/paths'

import 'styles/app.scss'
import styles from './Layout.module.scss'
import faviconImage from './images/favicon.png'
import logoImage from './images/logo.png'

export default function Layout({ children, className }) {
  return (
    <>
      <Head>
        <html lang="en" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@dustinhorton" />
        <link rel="icon" href={faviconImage} />
      </Head>
      <div className={classNames('Layout', styles.this, className)}>
        <Text align="center" element="header" className={styles.header}>
          <Link to={paths.HOME}>
            <Image src2x={logoImage} className={classNames(styles.logo, styles['logo---placeholder'])} aria-hidden />
            <Image src2x={logoImage} alt="Flow Metal Fabrication" className={styles.logo} />
          </Link>
          <Text casing="upper" copy element="div" className={styles.meta}>
            <Link to={meta.EMAIL}>
              {meta.EMAIL}
            </Link>
            {' '}
            —
            {' '}
            <Link to={meta.PHONE}>
              {meta.PHONE}
            </Link>
            {' '}
            —
            {' '}
            <Link to={`http://maps.google.com/?q=${meta.ADDRESS}`}>
              {meta.ADDRESS}
            </Link>
          </Text>
          <Text casing="upper" copy element="div">
            <div>
              {' '}
              —
              {' '}
            </div>
            Licensed + Insured
          </Text>
        </Text>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
}