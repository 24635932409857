const testimonials = [
  {
    author: 'C. Holmoe',
    copy: 'We love our railing! We showed Kevin a photo of the style we wanted, and within a couple days we had our custom railing done and ready for install. It turned out exactly how we wanted. The quality and fit of our rails exceeded our expectations. He was flexible and easy to work with. I’d highly recommend getting your railing done from Kevin!',
  },
  {
    author: 'J. Scoville',
    copy: 'Kevin at Flow Metal Fab has been great to work with. I recently remodeled my home and Kevin has worked on a few different projects. He built custom stair railings, custom curtain rods, and other custom pieces. His finished pieces are beautiful works of art and durable as well. When people walk into our home and see the stair railing Kevin made, they love it! I get so many compliments. Kevin is friendly, easy to work with, and has good suggestions. I have never had a hard time getting in touch with Kevin and he has been very good about staying on schedule. I highly recommend Kevin and his work.',
  },
  {
    author: 'C. Anderson',
    copy: 'Kevin exceeded our expectations on every project he’s worked on at our home. His quality of work and eye for design is as good as it gets!',
  },
  {
    author: 'B. Bowen',
    copy: 'Kevin is not only an exceptionally talented and creative fabricator he is also a thoughtful and reliable professional.',
  },
  {
    author: 'W. Bradshaw',
    copy: 'I have used Kevin with Flow Metal Fab twice in my home.  Each time was a five star experience.  He was able to understand the vision for the projects, provided options, and communicated through the entire project. The quality of his work is show-stopping.  I highly recommend Kevin and plan on utilizing him again in the future.',
  },
  {
    author: 'A. Orton',
    copy: 'I absolutely love my railing and everybody gives me huge compliments on it.',
  },
]

export default testimonials